import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/owners-offices/',
    name: 'OwnersOffices',
    component: () => import('~/views/owners-offices/OwnersOffices.vue'),
    meta: {
      title: 'Кабинеты собственников',
    }
  },
  // {START} monetization
  {
    path: '/owners-offices/monetization/import-price-list',
    name: 'ImportPriceList',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportPriceList.vue'),
    meta: {
      title: 'Загрузка прайс листа',
    }
  },

  {
    path: '/owners-offices/monetization/import-goods-and-shops',
    name: 'ImportGoodsAndShops',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportGoodsAndShops.vue'),
    meta: {
      title: 'Загрузка Товаров и Магазинов для Маркетплейса',
    }
  },

  {
    path: '/owners-offices/monetization/import-additional-services',
    name: 'ImportAdditionalServices',
    component: () =>
    import('~/views/owners-offices/file-analysis/ImportAdditionalServices.vue'),
    meta: {
      title: 'Загрузка доп.услуг для Маркетплейса',
    }
  },
  // {END} monetization
  // {START} payments
  {
    path: '/owners-offices/payments/act-of-service-provision-report/',
    name: 'ActOfServiceProvisionReport',
    component: () => import('~/views/owners-offices/payments/ActOfServiceProvisionReport.vue'),
    meta: {
      title: 'Акт об оказании услуг',
    }
  },
  // {END} payments

  // {START} reports
  {
    path: '/owners-offices/reports/account-info-report/',
    name: 'AccountInfoReport',
    component: () => import('~/views/owners-offices/reports/AccountInfoReport.vue'),
    meta: {
      title: 'Информация по лицевому счету',
    }
  },
  {
    path: '/owners-offices/reports/houses-info-report/',
    name: 'HousesInfoReport',
    component: () => import('~/views/owners-offices/reports/HousesInfoReport.vue'),
    meta: {
      title: 'Информация по домам',
    }
  },
  {
    path: '/owners-offices/reports/account-extract-report/',
    name: 'AccountExtractReport',
    component: () => import('~/views/owners-offices/reports/AccountExtractReport.vue'),
    meta: {
      title: 'Выписка из лицевого счета',
    }
  },
  {
    path: '/owners-offices/reports/house-extract-report/',
    name: 'HouseExtractReport',
    component: () => import('~/views/owners-offices/reports/HouseExtractReport.vue'),
    meta: {
      title: 'Выписка по дому',
    }
  },
    {
    path: '/owners-offices/reports/company-extract-report/',
    name: 'CompanyExtractReport',
    component: () => import('~/views/owners-offices/reports/CompanyExtractReport.vue'),
    meta: {
      title: 'Выписка по организации',
    }
  },
  {
    path: '/owners-offices/reports/accounting-and-payment-report/',
    name: 'AccountingAndPaymentReport',
    component: () => import('~/views/owners-offices/reports/AccountingAndPaymentReport.vue'),
    meta: {
      title: 'Проверка начислений и платежей (Услуги)',
    }
  },
  {
    path: '/owners-offices/reports/accounting-and-payment-by-ident-report/',
    name: 'AccountingAndPaymentByIdentReport',
    component: () => import('~/views/owners-offices/reports/AccountingAndPaymentByIdentReport.vue'),
    meta: {
      title: 'Проверка начислений и платежей (ЛС)',
    }
  },
  {
    path: '/owners-offices/reports/checking-reference-integrity-report/',
    name: 'CheckingReferenceIntegrityReport',
    component: () => import('~/views/owners-offices/reports/CheckingReferenceIntegrityReport.vue'),
    meta: {
      title: 'Отчет о нарушении связанности данных',
    }
  },
  {
    path: '/owners-offices/reports/meter-exist-values-report/',
    name: 'MeterExistValuesReport',
    component: () => import('~/views/owners-offices/reports/MeterExistValuesReport.vue'),
    meta: {
      title: 'Имеющиеся показания по приборам учета',
    }
  },
  {
    path: '/owners-offices/reports/checking-bills-report/',
    name: 'CheckingBillsReport',
    component: () => import('~/views/owners-offices/reports/CheckingBillsReport.vue'),
    meta: {
      title: 'Проверка квитанций',
    }
  },
  // {END} reports

  // {START} analytical reports
  {
    path: '/owners-offices/analytical-reports/mobile-users-analisis-report/',
    name: 'MobileUsersAnalisisReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileUsersAnalisisReport.vue'),
    meta: {
      title: 'Анализ использования мобильного приложения',
    }
  },
  {
    path: '/owners-offices/analytical-reports/meter-values-report/',
    name: 'MeterValuesReport',
    component: () => import('~/views/owners-offices/analytical-reports/MeterValuesReport.vue'),
    meta: {
      title: 'Показания приборов учета',
    }
  },
  {
    path: '/owners-offices/analytical-reports/house-meter-values-received-report/',
    name: 'HouseMeterValuesReceivedReport',
    component: () => import('~/views/owners-offices/analytical-reports/HouseMeterValuesReceivedReport.vue'),
    meta: {
      title: 'Показания ОДПУ, переданные через МП',
    }
  },
  {
    path: '/owners-offices/analytical-reports/meters-qr-codes-report/',
    name: 'MetersQrCodesReport',
    component: () => import('~/views/owners-offices/analytical-reports/MetersQrCodesReport.vue'),
    meta: {
      title: 'Отчет по QR-кодам приборов учета',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-announcement-report/',
    name: 'MobileAnnouncementReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileAnnouncementReport.vue'),
    meta: {
      title: 'Статистика просмотров уведомлений',
    }
  },
  {
    path: '/owners-offices/analytical-reports/payments-registry-report/',
    name: 'PaymentsRegistryReport',
    component: () => import('~/views/owners-offices/analytical-reports/PaymentsRegistryReport.vue'),
    meta: {
      title: 'Отчет по реестру оплат',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-users-in-dynamics-report/',
    name: 'MobileUsersInDynamicsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileUsersInDynamicsReport.vue'),
    meta: {
      title: 'Динамика роста пользователей мобильного приложения',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-detailed-statistics-report/',
    name: 'MobileDetailedStatisticsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileDetailedStatisticsReport.vue'),
    meta: {
      title: 'Детальная статистика',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-summary-statistics-report/',
    name: 'MobileSummaryStatisticsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileSummaryStatisticsReport.vue'),
    meta: {
      title: 'Сводная статистика',
    }
  },
  // {
  //   path: '/owners-offices/analytical-reports/sessions-log/',
  //   name: 'SessionsLog',
  //   component: () => import('~/views/owners-offices/analytical-reports/SessionsLog.vue'),
  //   meta: {
  //     title: 'Журнал авторизации',
  //   }
  // },
  {
    path: '/owners-offices/analytical-reports/callback-requests-report/',
    name: 'CallbackRequestsReport',
    component: () => import('~/views/owners-offices/analytical-reports/CallbackRequestsReport.vue'),
    meta: {
      title: 'Обращения с сайта',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-accounts-info-report/',
    name: 'MobileAccountsInfoReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileAccountsInfoReport.vue'),
    meta: {
      title: 'Отчет по пользователям',
    }
  },
  {
    path: '/owners-offices/analytical-reports/idents-without-mobile-account-report/',
    name: 'IdentsWithoutMobileAccountReport',
    component: () => import('~/views/owners-offices/analytical-reports/IdentsWithoutMobileAccountReport.vue'),
    meta: {
      title: 'Отчет по лицевым счетам не привязанным к моб.аккаунтам',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-payments-report/',
    name: 'MobilePaymentsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobilePaymentsReport.vue'),
    meta: {
      title: 'Отчет по мобильным платежам',
    }
  },
  {
    path: '/owners-offices/analytical-reports/payments-vs-paychecks-report/',
    name: 'PaymentsVsPaychecksReport',
    component: () => import('~/views/owners-offices/analytical-reports/PaymentsVsPaychecksReport.vue'),
    meta: {
      title: 'Отчет по чекам',
    }
  },
  {
    path: '/owners-offices/analytical-reports/loaded-receipts-report/',
    name: 'LoadedReceiptsReport',
    component: () => import('~/views/owners-offices/analytical-reports/LoadedReceiptsReport.vue'),
    meta: {
      title: 'Отчет по загруженным квитанциям',
    }
  },
  {
    path: '/owners-offices/analytical-reports/meter-values-robot-report/',
    name: 'MeterValuesRobotReport',
    component: () => import('~/views/owners-offices/analytical-reports/MeterValuesRobotReport.vue'),
    meta: {
      title: 'Отчет по роботу по приему ППУ',
    }
  },
  {
    path: '/owners-offices/analytical-reports/robot-for-meter-values-report/',
    name: 'RobotForMeterValuesReport',
    component: () => import('~/views/owners-offices/analytical-reports/RobotForMeterValuesReport.vue'),
    meta: {
      title: 'Отчет по роботу по приему показаний',
    }
  },
  {
    path: '/owners-offices/analytical-reports/robot-requests-report/',
    name: 'RobotRequestsReport',
    component: () => import('~/views/owners-offices/analytical-reports/RobotRequestsReport.vue'),
    meta: {
      title: 'Отчет по роботу-диспетчеру',
    }
  },
  // {
  //   path: '/owners-offices/analytical-reports/transitions-to-external-resources/',
  //   name: 'TransitionsToExternalResources',
  //   component: () => import('~/views/owners-offices/analytical-reports/TransitionsToExternalResources.vue'),
  //   meta: {
  //     title: 'Переходы на внешние ресурсы',
  //   }
  // },
  {
    path: '/owners-offices/analytical-reports/moe-accounts-report/',
    name: 'MoeAccountsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MoeAccountsReport.vue'),
    meta: {
      title: 'Отчет по подключенным лиц. счетам МОЕ',
    }
  },
  // {END} analytical reports

  // {START} bonus-system
  {
    path: '/owners-offices/bonus-system/bonus-cash-flow-report/',
    name: 'BonusCashFlowReport',
    component: () => import('~/views/owners-offices/bonus-system/BonusCashFlowReport.vue'),
    meta: {
      title: 'Отчет по бонусам',
    }
  },
  // {END} bonus-system

  // {START} surveys
  {
    path: '/owners-offices/surveys/questions-report/',
    name: 'QuestionsReport',
    component: () => import('~/views/owners-offices/surveys/QuestionsReport.vue'),
    meta: {
      title: 'Отчет по опросам',
    }
  },
  {
    path: '/owners-offices/surveys/question-results-report/',
    name: 'QuestionResultsReport',
    component: () => import('~/views/owners-offices/surveys/QuestionResultsReport.vue'),
    meta: {
      title: 'Результаты опросов',
    }
  },
  // {END} surveys
  // {START} loading from excel
  {
    path: '/owners-offices/loading-from-excel/import-supply-contract-info/',
    name: 'OwnersOfficesImportSupplyContractInfo',
    component: () => import('~/views/owners-offices/file-analysis/ImportSupplyContractInfo.vue'),
    meta: {
      title: 'Загрузка дат ДРСО',
    }
  },
  {
    path: '/owners-offices/loading-from-excel/import-fias',
    name: 'OwnersOfficesImportFias',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportFias.vue'),
    meta: {
      title: 'Загрузка ФИАС домов',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-fias-address',
    name: 'OwnersOfficesImportFiasAddress',
    component: () =>
      import(
        '~/views/owners-offices/file-analysis/ImportFiasAddress.vue'
      ),
    meta: {
      title: 'Загрузка адресов домов по ФИАС коду',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-executing-jobs',
    name: 'OwnersOfficesImportExecutingJobs',
    component: () =>
      import(
        '~/views/owners-offices/file-analysis/ImportExecutingJobs.vue'
      ),
    meta: {
      title: 'Загрузка выполняемых работ',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-meters',
    name: 'OwnersOfficesImportMeters',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportMeters.vue'),
    meta: {
      title: 'Загрузка приборов учета',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-meter-values',
    name: 'OwnersOfficesImportMeterValues',
    component: () =>
      import(
        '~/views/owners-offices/file-analysis/ImportMeterValues.vue'
      ),
    meta: {
      title: 'Загрузка показаний приборов учета',
    }
  },
  {
    path: '/owners-offices/loading-from-excel/import-premises',
    name: 'OwnersOfficesImportPremises',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportPremises.vue'),
    meta: {
      title: 'Загрузка помещений',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/bills-check',
    name: 'OwnersOfficesBillsCheck',
    component: () =>
      import('~/views/owners-offices/file-analysis/BillsCheck.vue'),
    meta: {
      title: 'Проверка загрузки квитанций',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-debt-cases',
    name: 'OwnersOfficesImportDebtCases',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportDebtCases.vue'),
    meta: {
      title: 'Загрузка дел должников (расширенная)',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-supply-contract-guids',
    name: 'OwnersOfficesImportSupplyContractGuids',
    component: () =>
      import(
        '~/views/owners-offices/file-analysis/ImportSupplyContractGuids.vue'
      ),
    meta: {
      title: 'Загрузка GUID ДРСО',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-work-lists',
    name: 'OwnersOfficesImportWorkLists',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportWorkLists.vue'),
    meta: {
      title: 'Загрузка перечней и планов работ',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-houses',
    name: 'OwnersOfficesImportHouses',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportHouses.vue'),
    meta: {
      title: 'Загрузка домов',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-account-dept-services',
    name: 'OwnersOfficesImportAccountDebtServices',
    component: () => import('~/views/owners-offices/file-analysis/ImportAccountDebtServices.vue'),
    meta: {
      title: 'Загрузка квитанций',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-bills-extended',
    name: 'OwnersOfficesImportBillsExtended',
    component: () =>
      import(
        '~/views/owners-offices/file-analysis/ImportBillsExtended.vue'
      ),
    meta: {
      title: 'Загрузка квитанций расширенная',
    }
  },

  {
    path: '/owners-offices/loading-from-excel/import-payments',
    name: 'OwnersOfficesImportPayments',
    component: () =>
      import('~/views/owners-offices/file-analysis/ImportPayments.vue'),
    meta: {
      title: 'Загрузка оплат',
    }
  },
  // {END} loading from excel  
  // {START} long-process
  {
    path: '/owners-offices/meter-recheck-caller-processor',
    name: 'MeterRecheckCallerProcessor',
    component: () => import('~/views/owners-offices/long-process/MeterRecheckCallerProcessor.vue'),
    meta: {
      title: 'Запуск звонков через UIS с уведомлением о поверке прибора учета',
    }
  },
  {
    path: '/owners-offices/import-capital-fund-size-processor',
    name: 'OwnersOffices.ImportCapitalFundSizeProcessor',
    component: () => import('~/views/gis/long-process/ImportCapitalFundSizeProcessor.vue'),
    meta: {
      title: 'Загрузка данных о размере фондов капитального ремонта',
    }
  },
  {
    path: '/owners-offices/archive-meters-by-excel-processor',
    name: 'ArchiveMetersByExcelProcessor',
    component: () => import('~/views/owners-offices/long-process/ArchiveMetersByExcelProcessor.vue'),
    meta: {
      title: 'Архивация приборов учета ИПУ списком из Excel',
    }
  },
  {
    path: '/owners-offices/archive-meters-processor',
    name: 'ArchiveMetersProcessor',
    component: () => import('~/views/owners-offices/long-process/ArchiveMetersProcessor.vue'),
    meta: {
      title: 'Архивация приборов учета ИПУ',
    }
  },
  {
    path: '/owners-offices/close-accounts-by-excel-processor',
    name: 'CloseAccountsByExcelProcessor',
    component: () => import('~/views/owners-offices/long-process/CloseAccountsByExcelProcessor.vue'),
    meta: {
      title: 'Массовое закрытие ЛС списком из Excel',
    }
  },
  {
    path: '/owners-offices/close-accounts-processor',
    name: 'CloseAccountsProcessor',
    component: () => import('~/views/owners-offices/long-process/CloseAccountsProcessor.vue'),
    meta: {
      title: 'Массовое закрытие ЛС',
    }
  },
  {
    path: '/owners-offices/account-from-tko-template-processor',
    name: 'OwnersOffices.AccountFromTkoTemplateProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountFromTkoTemplateProcessor.vue'),
    meta: {
      title: 'Обновить ИЖКУ по результатам обработки шаблона ЛС ТКО',
    }
  },
  {
    path: '/owners-offices/accounts-csv-file-processor',
    name: 'OwnersOffices.AccountsCsvFileProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountsCsvFileProcessor.vue'),
    meta: {
      title: 'Выгрузить ЛС/ЕЛС в CSV',
    }
  },
  {
    path: '/owners-offices/accounts-excel-file-processor',
    name: 'OwnersOffices.AccountsExcelFileProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountsExcelFileProcessor.vue'),
    meta: {
      title: 'Выгрузить ЛС/ЕЛС в Excel',
    }
  },
  {
    path: '/owners-offices/bills-delete-by-period-processor',
    name: 'BillsDeleteByPeriodProcessor',
    component: () => import('~/views/owners-offices/long-process/BillsDeleteByPeriodProcessor.vue'),
    meta: {
      title: 'Удалить квитанции за период',
    }
  },
  {
    path: '/owners-offices/create-cr-accounts-processor',
    name: 'OwnersOffices.CreateCRAccountsProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/CreateCRAccountsProcessor.vue'),
    meta: {
      title: 'Создать лицевые счета для кап.ремонта',
    }
  },
  {
    path: '/owners-offices/delete-meter-values-by-period-processor',
    name: 'DeleteMeterValuesByPeriodProcessor',
    component: () => import('~/views/owners-offices/long-process/DeleteMeterValuesByPeriodProcessor.vue'),
    meta: {
      title: 'Удаление показаний за период',
    }
  },
  {
    path: '/owners-offices/export-houses-to-excel-processor',
    name: 'OwnersOffices.ExportHousesToExcelProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ExportHousesToExcelProcessor.vue'),
    meta: {
      title: 'Выгрузить доп. информацию по домам (XLSX)',
    }
  },
  {
    path: '/owners-offices/export-houses-without-fias-processor',
    name: 'OwnersOffices.ExportHousesWithoutFiasProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ExportHousesWithoutFiasProcessor.vue'),
    meta: {
      title: 'Выгрузить дома без ФИАС в CSV файл',
    }
  },
  {
    path: '/owners-offices/ExportPremiseGisNumberProcessor',
    name: 'OwnersOffices.ExportPremiseGisNumberProcessor',
    component: () => import('~/views/interactiveAccess/long-process/ExportPremiseGisNumberProcessor.vue'),
    meta: {
      title: 'Экспорт номеров помещений ГИС в Excel',
    }
  },
  {
    path: '/owners-offices/fill-empty-types-services-for-bills-processor',
    name: 'FillEmptyTypesServicesForBillsProcessor',
    component: () => import('~/views/owners-offices/long-process/FillEmptyTypesServicesForBillsProcessor.vue'),
    meta: {
      title: 'Заполнить пустые типы услуг в квитанциях',
    }
  },
  {
    path: '/owners-offices/fill-template-tko-accounts-processor',
    name: 'OwnersOffices.FillTemplateTKOAccountsProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/FillTemplateTKOAccountsProcessor.vue'),
    meta: {
      title: 'Выгрузить ЛС ТКО',
    }
  },
  {
    path: '/owners-offices/fill-template-with-tko-bills-processor',
    name: 'FillTemplateWithTkoBillsProcessor',
    component: () => import('~/views/owners-offices/long-process/FillTemplateWithTkoBillsProcessor.vue'),
    meta: {
      title: 'Выгрузить квитанции ТКО',
    }
  },
  {
    path: '/owners-offices/house-creation-master-processor',
    name: 'OwnersOffices.HouseCreationMasterProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseCreationMasterProcessor.vue'),
    meta: {
      title: 'Мастер создания дома',
    }
  },
  {
    path: '/owners-offices/house-fiases-processor',
    name: 'OwnersOffices.HouseFiasesProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseFiasesProcessor.vue'),
    meta: {
      title: 'Загрузить ФИАСы',
    }
  },
  {
    path: '/owners-offices/houses-for-tko-contract-processor',
    name: 'OwnersOffices.HousesForTkoContractProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HousesForTkoContractProcessor.vue'),
    meta: {
      title: 'Заполнить шаблон по добавлению ОЖФ в договор ТКО(XLSX)',
    }
  },
  {
    path: '/owners-offices/house-type-by-premise-type-processor',
    name: 'OwnersOffices.HouseTypeByPremiseTypeProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseTypeByPremiseTypeProcessor.vue'),
    meta: {
      title: 'Изменение типа домов по кол-ву помещений',
    }
  },
  {
    path: '/owners-offices/import-houses-from-excel-processor',
    name: 'OwnersOffices.ImportHousesFromExcelProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ImportHousesFromExcelProcessor.vue'),
    meta: {
      title: 'Загрузить доп. информацию по домам (XLSX)',
    }
  },
  {
    path: '/owners-offices/merge-service-on-bills-processor',
    name: 'MergeServiceOnBillsProcessor',
    component: () => import('~/views/owners-offices/long-process/MergeServiceOnBillsProcessor.vue'),
    meta: {
      title: 'Слияние услуги/ресурса',
    }
  },
  {
    path: '/owners-offices/premises-not-linked-to-account-remove-processor',
    name: 'OwnersOffices.PremisesNotLinkedToAccountRemoveProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/PremisesNotLinkedToAccountRemoveProcessor.vue'),
    meta: {
      title: 'Удалить помещения, не привязанные к ЛС',
    }
  },
  {
    path: '/owners-offices/refill-service-types-in-bills-processor',
    name: 'RefillServiceTypesInBillsProcessor',
    component: () => import('~/views/owners-offices/long-process/RefillServiceTypesInBillsProcessor.vue'),
    meta: {
      title: 'Перезаполнение типов услуг в квитанциях',
    }
  },
  {
    path: '/interactive-access/update-houses-addresses-processor',
    name: 'OwnersOffices.UpdateHousesAddressesProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/UpdateHousesAddressesProcessor.vue'),
    meta: {
      title: 'Обновление адресов домов',
    }
  },
  {
    path: '/owners-offices/export-tko-payments-info-processor',
    name: 'ExportTkoPaymentsInfoProcessor',
    component: () => import('~/views/owners-offices/long-process/ExportTkoPaymentsInfoProcessor.vue'),
    meta: {
      title: 'Выгрузка оплат ТКО',
    }
  },
  {
    path: '/owners-offices/create-bills-pdf-processor',
    name: 'CreateBillsPdfProcessor',
    component: () => import('~/views/owners-offices/long-process/CreateBillsPdfProcessor.vue'),
    meta: {
      title: 'Генерация pdf-квитанций',
    }
  },
  // {END} long-process
];

