import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/new-mailing/notification-accounts-import',
    name: 'ImportNotificationAccounts',
    component: () =>
      import('~/views/mailingDispatcher/file-analysis/ImportNotificationAccounts.vue'),
    meta: {
      title: 'Загрузка абонентов по списку из файла'
    }
  },
];

