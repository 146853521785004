import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/gis/',
    name: 'GIS',
    component: () => import('~/views/gis/Gis.vue'),
    meta: {
      title: 'ГИС ЖКХ',
    }
  },
  // {START} reports
  {
    path: '/gis/reports/account-export-errors/',
    name: 'GISAccountExportErrorsReport',
    component: () => import('~/views/gis/reports/GISAccountExportErrorsReport.vue'),
    meta: {
      title: 'Ошибки выгрузки ЛС в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/bill-export-errors/',
    name: 'GISBillExportErrorsReport',
    component: () => import('~/views/gis/reports/GISBillExportErrorsReport.vue'),
    meta: {
      title: 'Ошибки выгрузки ПД в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/individual-meter-export-error/',
    name: 'GISIndividualMeterExportErrorReport',
    component: () => import('~/views/gis/reports/GISIndividualMeterExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки ИПУ в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/individual-meter-value-export-error/',
    name: 'GISIndividualMeterValueExportErrorReport',
    component: () => import('~/views/gis/reports/GISIndividualMeterValueExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки показаний ИПУ в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/house-meter-export-error/',
    name: 'GISHouseMeterExportErrorReport',
    component: () => import('~/views/gis/reports/GISHouseMeterExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки ОДПУ в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/house-meter-value-export-error/',
    name: 'GISHouseMeterValueExportErrorReport',
    component: () => import('~/views/gis/reports/GISHouseMeterValueExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки показаний ОДПУ в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/payment-export-error-report/',
    name: 'GISPaymentExportErrorReport',
    component: () => import('~/views/gis/reports/GISPaymentExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки оплат в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/acknowledgment-export-errors-report/',
    name: 'GISAcknowledgmentExportErrorsReport',
    component: () => import('~/views/gis/reports/GISAcknowledgmentExportErrorsReport.vue'),
    meta: {
      title: 'Ошибки выгрузки квитирования в ГИС ЖКХ',
    }
  },
  // {END} reports

  // {START} debtors
  {
    path: '/gis/debtors/debt-request-statistics/',
    name: 'DebtRequestStatisticsReport',
    component: () => import('~/views/gis/debtors/DebtRequestStatisticsReport.vue'),
    meta: {
      title: 'Отчет по заявкам',
    }
  },
  {
    path: '/gis/debtors/debtors-report/',
    name: 'DebtorsReport',
    component: () => import('~/views/gis/debtors/DebtorsReport.vue'),
    meta: {
      title: 'Отчет по должникам',
    }
  },
  {
    path: '/gis/debtors/debtors-requests-report/',
    name: 'DebtorsRequestsReport',
    component: () => import('~/views/gis/debtors/DebtorsRequestsReport.vue'),
    meta: {
      title: 'Отчет по запросам из ОСЗН по должникам',
    }
  },
  // {END} debtors

  // {START} reports-statistic
  {
    path: '/gis/reports-statistic/upload-statistic/',
    name: 'GISUploadStatisticReport',
    component: () => import('~/views/gis/reports-statistic/GISUploadStatisticReport.vue'),
    meta: {
      title: 'Общая статистика выгрузки в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports-statistic/upload-statistic-by-house/',
    name: 'GISUploadStatisticByHouseReport',
    component: () => import('~/views/gis/reports-statistic/GISUploadStatisticByHouseReport.vue'),
    meta: {
      title: 'Статистика выгрузки в ГИС ЖКХ по домам',
    }
  },
  {
    path: '/gis/reports-statistic/export-common-statistics/',
    name: 'GISExportCommonStatisticsReport',
    component: () => import('~/views/gis/reports-statistic/GISExportCommonStatisticsReport.vue'),
    meta: {
      title: 'Паспорт выгрузки в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports-statistic/upload-statistic-by-company-report/',
    name: 'GisUploadStatisticByCompanyReport',
    component: () => import('~/views/gis/reports-statistic/GisUploadStatisticByCompanyReport.vue'),
    meta: {
      title: 'Статистика по организациям',
    }
  },
  {
    path: '/gis/reports-statistic/withdrawn-bills-count-report/',
    name: 'WithdrawnBillsCountReport',
    component: () => import('~/views/gis/reports-statistic/WithdrawnBillsCountReport.vue'),
    meta: {
      title: 'Статистика по отозванным ПД',
    }
  },
  // {END} reports-statistic

  // {START} contract
  {
    path: '/gis/contract/contract-bills-report/',
    name: 'GISContractBillsReport',
    component: () => import('~/views/gis/contract/GISContractBillsReport.vue'),
    meta: {
      title: 'Отчет о выгрузке счет-квитанций',
    }
  },
  {
    path: '/gis/contract/contract-idents-report/',
    name: 'GISContractIdentsReport',
    component: () => import('~/views/gis/contract/GISContractIdentsReport.vue'),
    meta: {
      title: 'Отчет о выгрузке по лицевым счетам',
    }
  },
  {
    path: '/gis/contract/contract-meters-report/',
    name: 'GISContractMetersReport',
    component: () => import('~/views/gis/contract/GISContractMetersReport.vue'),
    meta: {
      title: 'Отчет о выгрузке по приборам учета',
    }
  },
  {
    path: '/gis/contract/contract-meters-values-report/',
    name: 'GISContractMetersValuesReport',
    component: () => import('~/views/gis/contract/GISContractMetersValuesReport.vue'),
    meta: {
      title: 'Отчет о передаче показаний приборов учета',
    }
  },
  {
    path: '/gis/contract/contract-requests-report/',
    name: 'GISContractRequestsReport',
    component: () => import('~/views/gis/contract/GISContractRequestsReport.vue'),
    meta: {
      title: 'Отчет об информации по запросам',
    }
  },
  {
    path: '/gis/contract/contracts-report/',
    name: 'GISContractsReport',
    component: () => import('~/views/gis/contract/GISContractsReport.vue'),
    meta: {
      title: 'Отчет о выгрузке договоров',
    }
  },
  // {END} contract
   // { START } downloads
  {
    path: '/gis/downloads/import-supply-contract-info',
    name: 'ImportSupplyContractInfo',
    component: () =>
      import('~/views/gis/file-analysis/ImportSupplyContractInfo.vue'),
    meta: {
      title: 'Загрузка дат ДРСО',
    }
  },

  {
    path: '/gis/downloads/import-fias',
    name: 'GisImportFias',
    component: () => import('~/views/gis/file-analysis/ImportFias.vue'),
    meta: {
      title: 'Загрузка ФИАС домов',
    }
  },

  {
    path: '/gis/downloads/import-fias-address',
    name: 'ImportFiasAddress',
    component: () => import('~/views/gis/file-analysis/ImportFiasAddress.vue'),
    meta: {
      title: 'Загрузка адресов домов по ФИАС коду',
    }
  },

  {
    path: '/gis/downloads/import-executing-jobs',
    name: 'ImportExecutingJobs',
    component: () => import('~/views/gis/file-analysis/ImportExecutingJobs.vue'),
    meta: {
      title: 'Загрузка выполняемых работ',
    }
  },

  {
    path: '/gis/downloads/import-meters',
    name: 'ImportMeters',
    component: () => import('~/views/gis/file-analysis/ImportMeters.vue'),
    meta: {
      title: 'Загрузка приборов учета',
    }
  },

  {
    path: '/gis/downloads/import-meter-values',
    name: 'ImportMeterValues',
    component: () => import('~/views/gis/file-analysis/ImportMeterValues.vue'),
    meta: {
      title: 'Загрузка показаний приборов учета',
    }
  },
  {
    path: '/gis/downloads/import-premises',
    name: 'GisImportPremises',
    component: () => import('~/views/gis/file-analysis/ImportPremises.vue'),
    meta: {
      title: 'Загрузка помещений',
    }
  },

  {
    path: '/gis/downloads/bills-check',
    name: 'GisBillsCheck',
    component: () => import('~/views/gis/file-analysis/BillsCheck.vue'),
    meta: {
      title: 'Проверка загрузки квитанций',
    }
  },

  {
    path: '/gis/downloads/import-debt-cases',
    name: 'GisImportDebtCases',
    component: () => import('~/views/gis/file-analysis/ImportDebtCases.vue'),
    meta: {
      title: 'Загрузка дел должников (расширенная)',
    }
  },

  {
    path: '/gis/downloads/import-supply-contract-guids',
    name: 'GisImportSupplyContractGuids',
    component: () =>
      import('~/views/gis/file-analysis/ImportSupplyContractGuids.vue'),
    meta: {
      title: 'Загрузка GUID ДРСО',
    }
  },

  {
    path: '/gis/downloads/import-work-lists',
    name: 'GisImportWorkLists',
    component: () => import('~/views/gis/file-analysis/ImportWorkLists.vue'),
    meta: {
      title: 'Загрузка перечней и планов работ',
    }
  },

  {
    path: '/gis/downloads/import-houses',
    name: 'GisImportHouses',
    component: () => import('~/views/gis/file-analysis/ImportHouses.vue'),
    meta: {
      title: 'Загрузка домов',
    }
  },

  {
    path: '/gis/downloads/import-account-dept-services',
    name: 'GisImportAccountDebtServices',
    component: () => import('~/views/gis/file-analysis/ImportAccountDebtServices.vue'),
    meta: {
      title: 'Загрузка квитанций',
    }
  },

  {
    path: '/gis/downloads/import-bills-extended',
    name: 'GisImportBillsExtended',
    component: () => import('~/views/gis/file-analysis/ImportBillsExtended.vue'),
    meta: {
      title: 'Загрузка квитанций расширенная',
    }
  },

  {
    path: '/gis/downloads/import-payments',
    name: 'GisImportPayments',
    component: () => import('~/views/gis/file-analysis/ImportPayments.vue'),
    meta: {
      title: 'Загрузка оплат',
    }
  },

  {
    path: '/gis/references/service-interruptions/import-service-interruptions',
    name: 'ImportServiceInterruptions',
    component: () => import('~/views/gis/file-analysis/ImportServiceInterruptionsByAccount.vue'),
    meta: {
      title: 'Загрузка перерывов по коммунальным услугам',
    }
  },

  // { END } downloads
  // {START} long-process
  {
    path: '/gis/audit-files-processor',
    name: 'AuditFilesProcessor',
    component: () => import('~/views/gis/long-process/AuditFilesProcessor.vue'),
    meta: {
      title: 'Формирование файлов аудита',
    }
  },
  {
    path: '/gis/import-capital-fund-size-processor',
    name: 'ImportCapitalFundSizeProcessor',
    component: () => import('~/views/gis/long-process/ImportCapitalFundSizeProcessor.vue'),
    meta: {
      title: 'Загрузка данных о размере фондов капитального ремонта',
    }
  },
  {
    path: '/gis/acknowledgement-processor',
    name: 'AcknowledgementProcessor',
    component: () => import('~/views/gis/long-process/AcknowledgementProcessor.vue'),
    meta: {
      title: 'Связи для квитирования',
    }
  },
  {
    path: '/gis/account-contracts-guid-by-objects-processor',
    name: 'AccountContractsGuidByObjectsProcessor',
    component: () => import('~/views/gis/long-process/AccountContractsGuidByObjectsProcessor.vue'),
    meta: {
      title: 'Записать Guidы договоров в ЛС (найти лс по ОЖФ)',
    }
  },
  {
    path: '/gis/account-contracts-guid-update-processor',
    name: 'AccountContractsGuidUpdateProcessor',
    component: () => import('~/views/gis/long-process/AccountContractsGuidUpdateProcessor.vue'),
    meta: {
      title: 'Записать Guidы договоров в ЛС (найти лс по № договора)',
    }
  },
  {
    path: '/gis/add-tariff-on-supply-contract-processor',
    name: 'AddTariffOnSupplyContractProcessor',
    component: () => import('~/views/gis/long-process/AddTariffOnSupplyContractProcessor.vue'),
    meta: {
      title: 'Заполнение договоров тарифами',
    }
  },
  {
    path: '/gis/fill-communal-supply-information-processor',
    name: 'FillCommunalSupplyInformationProcessor',
    component: () => import('~/views/gis/long-process/FillCommunalSupplyInformationProcessor.vue'),
    meta: {
      title: 'Заполнение информации по КУ и КР на основе ПД',
    }
  },
  {
    path: '/gis/supply-contracts-create-processor',
    name: 'SupplyContractsCreateProcessor',
    component: () => import('~/views/gis/long-process/SupplyContractsCreateProcessor.vue'),
    meta: {
      title: 'Создание недостающего договора на основе ДРСО',
    }
  },
  {
    path: '/gis/supply-contracts-objects-update-processor',
    name: 'SupplyContractsObjectsUpdateProcessor',
    component: () => import('~/views/gis/long-process/SupplyContractsObjectsUpdateProcessor.vue'),
    meta: {
      title: 'Обновить ОЖФ в договорах',
    }
  },
  {
    path: '/gis/supply-contracts-terminate-processor',
    name: 'SupplyContractsTerminateProcessor',
    component: () => import('~/views/gis/long-process/SupplyContractsTerminateProcessor.vue'),
    meta: {
      title: 'Расторгнуть ДРСО по закрытым л/сч',
    }
  },
  {
    path: '/gis/supply-contract-subjects-processor',
    name: 'SupplyContractSubjectsProcessor',
    component: () => import('~/views/gis/long-process/SupplyContractSubjectsProcessor.vue'),
    meta: {
      title: 'Обновить услуги на основе квитанций',
    }
  },
  {
    path: '/gis/debtors-requests-custom-report-processor',
    name: 'DebtorsRequestsCustomReportProcessor',
    component: () => import('~/views/gis/long-process/DebtorsRequestsCustomReportProcessor.vue'),
    meta: {
      title: 'Отчет по запросам сведений о должниках для ОСЗН',
    }
  },
  // {END} long-process
];


